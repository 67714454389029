import Axios from "../api/axios";
import config from "../../config";

const updateLetter = (formData) => {
  return Axios.post(`${config.BASE_URL}update-letter`, {
    formData: formData,
  });
};

export default updateLetter;
