const Routes = {
  LANDING_PAGE: { URL: "/" },
  HELP_PAGE: { URL: "/help" },
  TERMS_PAGE: { URL: "/terms" },
  RESET_PASSWORD: { URL: "/reset-password" },
  SET_PASSWORD: { URL: "/set-password" },
  LOGIN: { URL: "/login" },
  SIGNUP: { URL: "/signup" },
  FORGOTPASSWORD: { URL: "/forgot-password" },
  DATAINPUTS: { URL: "/data-inputs" },
  MOREDETAILS: { URL: "/case-details" },
  PREVIEWLETTER: { URL: "/preview-letter" },
  CUSTOMERACCESS: { URL: "/customer-access" },
  LETTER: { URL: "/letter" },
  DASHBOARD: { URL: "/dashboard" },
  FAQs: { URL: "/faqs" },
  PREMIUMSIGNUP: { URL: "/premium-signup" },
  UPDATELETTER: { URL: "/update-letter" },
  CHECKLETTER: { URL: "/check-letter" },
  GENERATEDLETTER: { URL: "/generated-letter" },
  GRAPPLELETTERS: { URL: "/monaco-solicitors-ai-letters" },
  PRICING: { URL: "/pricing" },
  PROFILE: { URL: "/profile" },
  LETTERDETAIL: { URL: "/monaco-solicitors-ai-letters/:slug" },
  SUBSCRIPTIONCANCELLED: { URL: "/subscription-cancelled" },
  CHOOSELETTER: { URL: "/choose-letter" },
  LAWYERACCESS: { URL: "/lawyer-access" },
  THANKYOU: { URL: "/thank-you" },
  CCLSIGN: { URL: "/ccl-sign" },
  CCLCOMPLETE: { URL: "/ccl-completed" },
  CLIENTREVIEWLETTER: { URL: "/client-review" },
  SUBMITTEDSUCCESSFULLY: { URL: "/submitted-successfully" },
  SUBMITTED: { URL: "/request-submitted" },
  INTERNALREVIEWLETTER: { URL: "/internal-review" },
  MERITASSESSMENT: { URL: "/merit-assessment" },
  CHATBOT: { URL: "/chatbot" },
  CHATBOTLAWYER: { URL: "/chatbot-lawyer" },
};

export default Routes;
