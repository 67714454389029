import React from "react";

function SubmittedSuccessfully() {
  return (
    <>
      <div className="ccl-completed container">
        <h2>Your comments have been submitted successfully!</h2>
      </div>
    </>
  );
}

export default SubmittedSuccessfully;
