import React from "react";
import { Menubar } from "primereact/menubar";
import logo from "../assets/img/ms-logo.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../shared/AuthContext";
import { removeToken } from "../shared/Helpers";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isChatbotPath = location.pathname.startsWith("/chatbot");
  const { user, setUser } = useAuthContext();
  const isLoggedIn = !!user;

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    removeToken();
    setUser(null);
    handleNavigation("/login");
  };

  const items = [
    {
      label: "Home",
      path: "/",
      command: () => handleNavigation("/"),
      className: "menu-item-home",
    },
    isLoggedIn
      ? {
          label: "Dashboard",
          path: "/dashboard",
          command: () => handleNavigation("/dashboard"),
          className: "menu-item-help",
        }
      : {
          label: "Monaco Solicitors AI Letters",
          path: "/monaco-solicitors-ai-letters",
          command: () => handleNavigation("/monaco-solicitors-ai-letters"),
          className: "menu-item-help",
        },
    {
      label: "Pricing",
      path: "/pricing",
      command: () => handleNavigation("/pricing"),
      className: "menu-item-pricing",
    },
    {
      label: "FAQs",
      path: "/help",
      command: () => handleNavigation("/help"),
      className: "menu-item-terms",
    },
  ];

  // Conditionally add the "Logout" item if the user is logged in
  if (!isLoggedIn) {
    items.push(
      {
        label: "Sign up",
        path: "/signup",
        command: () => handleNavigation("/signup"),
        className: "button menu-item-signup",
      },
      {
        label: "Login",
        path: "/login",
        command: () => handleNavigation("/login"),
        className: "button menu-item-login",
      }
    );
  }
  if (isLoggedIn) {
    items.push({
      label: "Logout",
      command: handleLogout,
      className: "button menu-item-login",
    });
  }

  items.forEach((item) => {
    if (item.path && location.pathname === item.path) {
      item.className += " active";
    }
  });

  const visibleItems = isChatbotPath ? [] : items;

  const navigateHomepage = () => {
    handleNavigation("/");
  };

  const start = (
    <div className="header-start">
      <img
        src={logo}
        alt="Logo"
        className="header-logo"
        onClick={navigateHomepage}
      />
    </div>
  );

  return (
    <div className="header">
      <Menubar
        className="header-component"
        model={visibleItems}
        start={start}
      />
    </div>
  );
};

export default Header;
