import Axios from "../api/axios";
import config from "../../config";

const sendMailToClient = (
  to,
  name,
  letterText,
  fileName,
  templateId,
  dealID
) => {
  return Axios.post(`${config.BASE_URL}send-email-to-client`, {
    to: to,
    name: name,
    letterText: letterText,
    fileName: fileName,
    templateId: templateId,
    dealID: dealID,
  });
};

export default sendMailToClient;
