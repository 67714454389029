import Axios from "../api/axios";
import config from "../../config";

const sendMailToEmployer = (
  to,
  name,
  letterText,
  fileName,
  templateId,
  dealID,
  client_name,
  encryptedDealID
) => {
  return Axios.post(`${config.BASE_URL}send-email-to-employer`, {
    to: to,
    name: name,
    letterText: letterText,
    fileName: fileName,
    templateId: templateId,
    dealID: dealID,
    client_name: client_name,
    encryptedDealID: encryptedDealID,
  });
};

export default sendMailToEmployer;
