import {
  GrappleAssistantMode,
  GrappleChat,
  GrappleClientProvider,
} from "@monaco-digital/grapple-assistant";
import "@monaco-digital/grapple-assistant/style.css";
import { getToken, setToken } from "../shared/Helpers";
import config from "../config";
import { useLocation } from "react-router";
import getAccessToken from "../app/services/getAccessToken";
import { useEffect, useState } from "react";
import { decryptEmail } from "../shared/Helpers";

// TODO: temporary measure to fetch a token
const decryptAndGetToken = async (encryptedEmail) => {
  if (!encryptedEmail) return;

  const decryptedEmail = decryptEmail(encryptedEmail);

  try {
    const response = await getAccessToken(decryptedEmail);
    const {
      data: { token },
    } = response;

    return token;
  } catch (error) {
    console.error("Failed to fetch token:", error);
  }
};

const Chatbot = ({ mode = GrappleAssistantMode.Client }) => {
  const [localToken, setLocalToken] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerQueryParam = queryParams.get("customer");

  const handleGetToken = async (customerQueryParam) => {
    const token = await decryptAndGetToken(customerQueryParam);
    const savedToken = getToken();
    const finalToken = token || savedToken;

    setToken(finalToken);
    setLocalToken(finalToken);
  };

  useEffect(() => {
    handleGetToken(customerQueryParam);
  }, [customerQueryParam]);

  return (
    <div className="chatbot">
      {localToken && (
        <GrappleClientProvider
          mode={mode}
          api={{
            baseURL: config.GRAPPLE_ASSISTANT_BASE_URL,
            clientId: config.GRAPPLE_ASSISTANT_CLIENT_ID,
          }}
          authToken={localToken}
        >
          <GrappleChat />
        </GrappleClientProvider>
      )}
    </div>
  );
};

export default Chatbot;
