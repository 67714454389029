import React from "react";
import Gif from "../assets/img/loader.gif";

const Loader = ({ text }) => {
  return (
    <div className="loader-section">
      <img src={Gif} alt="animated-gif" />
      <p>{text ? text : "Loading..."}</p>
    </div>
  );
};

export default Loader;
