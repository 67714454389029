import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ReactMarkdown from "react-markdown";
import { removeToken, setToken } from "../shared/Helpers";
import getAccessToken from "../app/services/getAccessToken";
import getUser from "../app/services/getUser";
import { useLocation, useNavigate } from "react-router-dom";
import Circle from "../assets/img/check_circle.svg";
import Edit from "../assets/img/edit_comment.svg";
import Loader from "../components/Loader";
import config from "../config";
import axios from "axios";
import updateUser from "../app/services/updateUser";
import Cycle from "../assets/img/cycle.svg";
import rehypeRaw from "rehype-raw";

const encryptEmail = (email) => {
  let result = "";
  for (let i = 0; i < email.length; i++) {
    let charCode = email.charCodeAt(i);
    let encryptedCharCode = charCode + 3;
    result += String.fromCharCode(encryptedCharCode);
  }
  return result;
};

const decryptEmail = (encryptedEmail) => {
  let result = "";
  for (let i = 0; i < encryptedEmail.length; i++) {
    let charCode = encryptedEmail.charCodeAt(i);
    let decryptedCharCode = charCode - 3;
    result += String.fromCharCode(decryptedCharCode);
  }
  return result;
};

const formatContent = (content) => {
  return content.replace(/\[(\d+)]/g, "\n[$1]");
};

const ClientReviewLetter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("customer");

  const [isLoading, setIsLoading] = useState(true);
  const [isLetterEdit, setLetterEdit] = useState(false);
  const [isLetterSubmitted, setLetterSubmitted] = useState(false);
  const [dealID, setDealID] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 5000;
  const [authToken, setAuthToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [pollingInterval, setPollingInterval] = useState(null);

  useEffect(() => {
    const decryptAndFetchToken = async () => {
      if (paramValue) {
        try {
          const decryptedEmail = decryptEmail(paramValue);
          const response = await getAccessToken(decryptedEmail);
          const data = response.data;
          const userDetails = await getUser(data.token);

          setAuthToken(data.token);
          setToken(data.token);
          setDealID(userDetails.data.dealID);
          setUserDetails(userDetails.data);
          setLetterSubmitted(userDetails.data.wpLetterReview);

          // If the letter hasn't been checked, start polling
          if (!userDetails.data.checkedWpLetter) {
            const intervalId = setInterval(async () => {
              try {
                const response = await getAccessToken(decryptedEmail); // reuse decryptedEmail
                const data = response.data;
                const updatedUserDetails = await getUser(data.token);
                setUserDetails(updatedUserDetails.data);

                // Stop polling if the letter is available
                if (updatedUserDetails.data.checkedWpLetter) {
                  clearInterval(intervalId);
                  setIsLoading(false);
                }
              } catch (error) {
                console.error("Error polling for the letter:", error);
              }
            }, 5000);

            setPollingInterval(intervalId);

            // Clean up polling on component unmount
            return () => clearInterval(intervalId);
          } else {
            setIsLoading(false);
          }
        } catch (e) {
          setErrorMessage("User not found");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } else {
        removeToken();
        navigate("/login");
      }
    };

    decryptAndFetchToken();
  }, [navigate, paramValue]);

  const edit = () => {
    setLetterEdit(true);
  };

  const approve = async () => {
    if (dealID) {
      setIsSubmitting(true);
      try {
        const encryptedUserEmail = encryptEmail(userDetails.email);
        const baseUrl = window.location.origin;
        const payload = {
          dealID: dealID,
          wpLetterUrl: `${baseUrl}/internal-review?customer=${encryptedUserEmail}`,
          stage: "trainee",
        };
        const updateField = {
          wpLetterReview: true,
        };
        await axios.post(`${config.ROTA_URL}submit-letter`, payload);
        await updateUser(authToken, updateField);
        navigate("/submitted-successfully");
      } catch (error) {
        console.error("Error generating and showing the letter:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      letterFeedback: "",
      clientAnswer: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.letterFeedback && !userDetails.SentBackToClient) {
        errors.letterFeedback = "Required";
      }
      if (!values.clientAnswer && userDetails.SentBackToClient) {
        errors.clientAnswer = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (dealID) {
        setIsSubmitting(true);
        try {
          const encryptedUserEmail = encryptEmail(userDetails.email);
          const baseUrl = window.location.origin;
          const payload = {
            dealID: dealID,
            comment: `Client comment: ${values.letterFeedback}`,
            wpLetterUrl: `${baseUrl}/internal-review?customer=${encryptedUserEmail}`,
            stage: "trainee",
            commentToClient: `${
              Array.isArray(userDetails.comments)
                ? userDetails.comments
                    .map((comment) => `${comment.type}: ${comment.comment}`)
                    .join("\n")
                : userDetails.comments || ""
            }${values.clientAnswer ? `\nClient: ${values.clientAnswer}` : ""}`,
          };

          const updateField = {
            wpLetterReview: true,
            userComments: values.letterFeedback,
            SentBackToClient: false,
            ...(values.clientAnswer && {
              ClientAnswered: true,
              comments: [
                ...(userDetails.comments || []),
                {
                  comment: values.clientAnswer,
                  timestamp: new Date().toISOString(),
                  type: "Client",
                },
              ],
            }),
          };
          await axios.post(`${config.ROTA_URL}submit-letter`, payload);
          await updateUser(authToken, updateField);
          navigate("/submitted-successfully");
        } catch (error) {
          console.error("Error generating and showing the letter:", error);
        } finally {
          setIsSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    if (
      userDetails.userComments !== undefined ||
      userDetails.ClientAnswer !== undefined
    ) {
      formik.setValues({
        letterFeedback: userDetails.userComments || "",
        clientAnswer: userDetails.ClientAnswer || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const formatAndHighlightContent = (content) => {
    if (!content) return "";

    // Replace text inside square brackets, excluding numeric-only content
    return content.replace(
      /\[(?!\d+\])([^\]]+)\]/g, // Matches non-numeric text inside brackets
      '<span class="highlight">[$1]</span>'
    );
  };

  const createMarkup = (comment) => {
    return comment.replace(/\n/g, "<br />");
  };

  return (
    <div className="letter client-review">
      <div className="letter-header-message">
        {!isLetterEdit && (
          <div className="letter-header">
            <p>
              Thank you for answering those questions, please see your draft
              letter below
            </p>
            This was created using our advanced AI but don’t worry our lawyers
            will review this before sending it out
          </div>
        )}
        {isLetterEdit && (
          <div className="letter-header">
            <p>Review and Comment on Your Draft Letter</p>
            Your feedback is crucial to ensure the accuracy and effectiveness of
            your letter
          </div>
        )}
      </div>

      <div className="letter-container">
        <div className="letter-body">
          {isLoading ? (
            <Loader text={"Loading letter, please wait..."} />
          ) : (
            <>
              <div>
                <div className="letter-content-container">
                  <h5>Without Prejudice Letter</h5>
                  <div id="content" className="letter-content logged-in">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {userDetails.checkedWpLetter &&
                        formatAndHighlightContent(
                          userDetails?.checkedWpLetter
                        ).replace(/<br\s*\/?>/gi, "")}
                    </ReactMarkdown>
                  </div>
                  {!isLetterEdit && !isLetterSubmitted && (
                    <div className="letter-actions">
                      <div
                        className={`check-letter edit ${
                          isSubmitting ? "disabled" : ""
                        }`} // Disable edit button
                        onClick={!isSubmitting ? edit : null} // Disable edit click if submitting
                      >
                        <img src={Edit} alt="check-letter-icon" /> Edit
                      </div>
                      <div
                        className={`send-email approve ${
                          isSubmitting ? "disabled" : ""
                        }`} // Disable approve button
                        onClick={!isSubmitting ? approve : null} // Disable approve click if submitting
                      >
                        <img src={Circle} alt="email-icon" />
                        Approve
                      </div>
                    </div>
                  )}
                </div>
                {(isLetterEdit || isLetterSubmitted) && (
                  <div className="comment">
                    <form onSubmit={formik.handleSubmit}>
                      {!userDetails.SentBackToClient && (
                        <>
                          <label htmlFor="letterFeedback">
                            Please review your draft letter above and add any
                            comments or corrections
                          </label>
                          <textarea
                            id="letterFeedback"
                            name="letterFeedback"
                            maxLength={maxCharLimit}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setCharCount(e.target.value.length);
                            }}
                            value={formik.values.letterFeedback}
                            readOnly={isLetterSubmitted}
                          />
                          {formik.errors.letterFeedback && (
                            <div className="error-message">
                              {formik.errors.letterFeedback}
                            </div>
                          )}
                          <span>
                            {charCount}/{maxCharLimit}
                          </span>
                        </>
                      )}
                      {userDetails.SentBackToClient && (
                        <div className="additional-fields">
                          {/* Chat UI for Comments */}
                          {userDetails.comments &&
                            userDetails.comments.length > 0 && (
                              <div className="comments-chat">
                                <h6>Comments History</h6>
                                <div className="comments-list">
                                  {userDetails.comments.map(
                                    (comment, index) => (
                                      <div
                                        key={index}
                                        className={`comment-item ${
                                          comment.type === "Internal"
                                            ? "left-align"
                                            : "right-align"
                                        }`}
                                      >
                                        <p className="comment-type">
                                          {comment.type === "Internal"
                                            ? "Lawyer"
                                            : "Me"}{" "}
                                          <span className="comment-timestamp">
                                            {new Date(
                                              comment.timestamp
                                            ).toLocaleString()}
                                          </span>
                                        </p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: createMarkup(
                                              comment.comment
                                            ),
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}

                          <label htmlFor="clientAnswer">Your Response</label>
                          <textarea
                            id="clientAnswer"
                            name="clientAnswer"
                            maxLength={maxCharLimit}
                            disabled={userDetails.ClientAnswered}
                            value={formik.values.clientAnswer}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setCharCount(e.target.value.length);
                            }}
                          />
                          {formik.errors.clientAnswer && (
                            <div className="error-message">
                              {formik.errors.clientAnswer}
                            </div>
                          )}
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={() => formik.handleSubmit()}
                      >
                        Submit comments
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {isSubmitting && (
        <div className="submit-loader">
          <div>
            <img src={Cycle} width="auto" height="auto" alt="cycle" />
            <p>Submitting</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientReviewLetter;
