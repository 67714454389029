import React from "react";

function CclComplete() {
  return (
    <>
      <div className="ccl-completed container">
        <h2>Thank you and congratulations on becoming a client!</h2>
        <p>Please check your email for next steps…</p>
        {/* 
        <div className="next-section">
          <h3>Whats next?</h3>
          <ul>
            <li>
              A copy of the signed agreement will be sent to your email shortly.
            </li>
            <li>
              Please check your inbox for further instructions, including a
              request to complete your timeline information.
            </li>
          </ul>
        </div>
        <p>
          If you have any questions or require further assistance, feel free to
          reach out to us.
        </p>
        <p>Thank you for your cooperation.</p> */}
      </div>
    </>
  );
}

export default CclComplete;
