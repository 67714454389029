const AUTH_TOKEN = "authToken";
const DEAL_ID = "dealId";
const LOGIN_TYPE = "loginType";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(LOGIN_TYPE);
};

export const setLoginType = (type) => {
  if (type) {
    localStorage.setItem(LOGIN_TYPE, type);
  }
};

export const getLoginType = () => {
  return localStorage.getItem(LOGIN_TYPE);
};

export const getDealId = () => {
  return localStorage.getItem(DEAL_ID);
};

export const setDealId = (dealId) => {
  if (dealId) {
    localStorage.setItem(DEAL_ID, dealId);
  }
};

export const removeDealId = () => {
  localStorage.removeItem(DEAL_ID);
};

export const formatString = (input) => {
  let stringWithoutHyphens = input.replace(/-/g, " ");

  return (
    stringWithoutHyphens.charAt(0).toUpperCase() + stringWithoutHyphens.slice(1)
  );
};

export const filterArrayByIssueType = (arr, issueTypeId) => {
  return arr.filter(
    (item) => item.attributes.issue_type.data.id === issueTypeId
  );
};

export const createImageElement = (
  source,
  altText,
  onClickCallback,
  height = "20px",
  width = "20px"
) => {
  const img = document.createElement("img");
  img.src = source;
  img.style.cssText = `height: ${height}; width: ${width}; cursor: pointer;`;
  img.alt = altText;
  img.onclick = onClickCallback;
  return img;
};

export const addThreeMonthsToDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  date.setMonth(date.getMonth() + 3);
  if (date.getDate() < day) {
    date.setDate(0);
  }
  date.setDate(date.getDate() - 1);
  return date;
};

export const decryptEmail = (encryptedEmail) => {
  let result = "";
  for (let i = 0; i < encryptedEmail.length; i++) {
    let charCode = encryptedEmail.charCodeAt(i);
    let decryptedCharCode = charCode - 3; // Use the same number used for encryption
    result += String.fromCharCode(decryptedCharCode);
  }
  return result;
};
