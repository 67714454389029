import React, { useEffect, useState } from "react";
import getAccessToken from "../app/services/getAccessToken";
import { removeToken, setToken } from "../shared/Helpers";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import getUser from "../app/services/getUser";

const decryptEmail = (encryptedEmail) => {
  let result = "";
  for (let i = 0; i < encryptedEmail.length; i++) {
    let charCode = encryptedEmail.charCodeAt(i);
    let decryptedCharCode = charCode - 3; // Use the same number used for encryption
    result += String.fromCharCode(decryptedCharCode);
  }
  return result;
};

function CustomerAccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("customer");
  const paramType = queryParams.get("type");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const decryptAndFetchToken = async () => {
      if (paramValue) {
        try {
          const decryptedEmail = decryptEmail(paramValue);
          const response = await getAccessToken(decryptedEmail);
          const data = response.data;
          const userDetails = await getUser(data.token);
          const userSurveyData = userDetails.data.survey;
          if (paramType === "assessment") {
            navigate("/merit-assessment");
          } else if (
            !userSurveyData.company_name &&
            userSurveyData.total_salary
          ) {
            navigate("/case-details?formType=contact");
          } else if (
            userSurveyData.total_salary &&
            userSurveyData.offer_received
          ) {
            navigate("/preview-letter");
          } else {
            navigate("/case-details");
          }
          console.log(userSurveyData);
          setToken(data.token);
        } catch (e) {
          setErrorMessage("User not found");
        }
      } else {
        removeToken();
        navigate("/login");
      }
    };

    decryptAndFetchToken();
  }, [navigate, paramType, paramValue]);

  return (
    <div className="login auth">
      <Loader />
      {errorMessage && <p className="error response">{errorMessage}</p>}
    </div>
  );
}

export default CustomerAccess;
